<template>
    <ValidationProvider
        :name="validationName"
        :rules="validationRules"
        v-slot="{ errors }"
    >
        <v-select
            dense
            :disabled="inputDisabled"
            :error-messages="errors"
            filled
            hide-details="auto"
            :id="id"
            :item-text="optionText"
            :item-value="optionValue"
            :items="optionItems"
            :multiple="inputMultiple"
            outlined
            :placeholder="inputPlaceholder"
            :prepend-inner-icon="inputIcon"
            :readonly="inputReadOnly"
            :type="inputType"
            :value="inputValue"
            @change="$emit('change', $event)"
            @input="$emit('input', $event)"
        />
    </ValidationProvider>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    name: "customSelectField",
    props: {
        id: { default: null },
        inputDisabled: { default: false },
        inputIcon: { default: null },
        inputMultiple: { default: false, type: Boolean },
        inputPlaceholder: { default: "Placeholder" },
        inputReadOnly: { default: false },
        inputType: { default: "text" },
        inputValue: { default: "" },
        optionItems: { default: [] },
        optionText: { default: "ItemText" },
        optionValue: { default: "ItemValue" },
        validationName: { default: "ValidationProviderName" },
        validationRules: { default: null },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

